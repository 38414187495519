/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import {
  Common,
  Icon,
  LayoutPartials,
  ModalInform,
  DeliveryStatusLabel,
  CustomScrollbar,
} from "@/components";
import {
  CanModifyRouteByStatus,
  DeliveryStatusValue,
  PATH,
  TIME_FORMAT,
  DOMAIN_URL,
  GOOGLE_MAP_ID,
} from "@/configuration/globalVariable";
import { timeFormat, SnackbarUtils, timeFormatHeader } from "@/utils";

import { GoogleMap } from "../components/Map";
import {
  DeliveryRouteDetailList,
  DeliveryRouteDetailEditingList,
} from "../components";

import {
  manifest,
  manifestDetailETA,
  manifestLine,
  storageAddress,
} from "../models/DeliveryManifest";
import {
  useSaveEditingRoutes,
  useForceStopDelivering,
  getManifestDetailTimeETA,
  getRouteById,
  getRouteETAWithMapIds,
  finishPendingRequests,
  useChangeStartTime,
} from "../services/deliveryManifest";
import { useAppSelector, useAppDispatch } from "@/sharedStore/hooks";
import {
  manifestsSelector,
  storageAddressSelector,
} from "../providers/deliveryManifest/selector";
import { updateManifests } from "../providers/deliveryManifest/slice";
import { fetchAllManifestByDateAsync } from "../providers/deliveryManifest/sliceServices";
import { sortManifestLine } from "../utils";
import { useGetRouteDetailById } from "../providers/deliveryManifest/hooks";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { ModalChangeStartTime } from "../components/ModalChangeStartTime";

const DeliveryRouteDetail = () => {
  const params = useParams();
  const { routeDetailId } = params;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const passedData = sortManifestLine((location.state as any)?.data);
  const getRouteDetailById = useGetRouteDetailById();
  const [isOpenSaveEditingModal, setOpenSaveEditingModal] = useState(false);
  const [isOpenChangeStartTimeModal, setOpenChangeStartTimeModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [routeDetail, setRouteDetail] = useState<manifest | any>(
    routeDetailId && getRouteDetailById(routeDetailId)
  );
  const [routeEditing, setRouteEditing] = useState<manifest | any>();
  const [routesMoving, setRoutesMoving] = useState<manifest[]>([]);
  const [isDirty, setDirty] = useState(false);
  const [unallocateList, setUnallocate] = useState<manifestLine[]>([]);
  const [isOpenBackInformModal, setIsOpenBackInformModal] = useState(false);
  const [isManifestETALoading, setIsManifestETALoading] =
    useState<boolean>(false);

  const storageAddress = useAppSelector(
    storageAddressSelector
  ) as storageAddress;
  const manifests = useAppSelector(manifestsSelector) as manifest[];
  const isEditing = Boolean(routeEditing);
  const destinationNumber = !isEditing
    ? routeDetail?.manifestLines?.length
    : routeEditing?.manifestLines?.length;

  const handleBackNoneData = () => {
    navigate(`/${PATH.DELIVERY._}/${PATH.DELIVERY.DELIVERY_MANIFESTS}`, {
      replace: true,
      state: { deliveryDate: routeDetail.deliveryDate },
    });
  };

  const handleBack = () => {
    setRouteEditing(undefined);
    passedData ? navigate(-1) : handleBackNoneData();
  };

  const handleEnableEdit = () => {
    setRouteEditing(routeDetail);
  };






  // const [map] = useState<google.maps.Map>();
  const mapRef = useRef<google.maps.Map>();
  const onLoad = (loadedMap: google.maps.Map) => {
    mapRef.current = loadedMap;
  };
  // const onBoundsChanged = () => {
  //   if (mapRef.current) {
  //     const bounds = mapRef.current.getBounds();
  //     console.log('onBoundsChange', bounds);
  //   }
  // };

  const truckTrackingRemover: any = {};
  const truckMarkerList: any = {};

  const TrackManifest = () => {
    const manifestId = routeDetail.id;
    const status = routeDetail.status;

    console.log("TrackManifest:" + manifestId);

    console.log("firebase.initializeApp");
    // test
    // firebase.initializeApp({
    //   apiKey: "AIzaSyDTAGWWDb_u9cD-9NMItsZgQT-S8uFkMp4",
    //   authDomain: "tom-driver-dev.firebaseapp.com",
    //   projectId: "tom-driver-dev",
    // });

    // prod
    firebase.initializeApp({
      apiKey: "AIzaSyAWDEOGYAtXQISaJFojz474xsG9VKNdUIk",
      authDomain: "tom-driver-prod.firebaseapp.com",
      projectId: "tom-driver-prod",
    });

    console.log("firebase.firestore()");
    const database = firebase.firestore();

    const icon = DOMAIN_URL + "/Content/imgs/delivery-truck-icon.png";
    console.log(icon);

    manifests.forEach(function (mani) {
      const key = mani.id;

      if (truckMarkerList[key]) {
        truckMarkerList[key].setMap(null);
        truckMarkerList[key] = null;
      }

      if (truckTrackingRemover[key]) {
        truckTrackingRemover[key]();
      }
    });

    manifests.forEach(function (mani) {
      const key = mani.id;

      if (truckMarkerList[key]) {
        truckMarkerList[key].setMap(null);
        truckMarkerList[key] = null;
      }

      if (truckTrackingRemover[key]) {
        truckTrackingRemover[key]();
      }
    });

    if (status == 1) {
      truckTrackingRemover[manifestId] = database
        .collection("manifests")
        .doc(manifestId)
        .onSnapshot((manifest) => {
          const manifestData = manifest.data();
          console.log("Current data: ", manifestData);

          if (truckMarkerList[manifestId])
            truckMarkerList[manifestId].setMap(null);

          console.log(mapRef.current);
          if (manifestData) {
            truckMarkerList[manifestId] = new google.maps.Marker({
              position: manifestData.latestLocation,
              // eslint-disable-next-line object-shorthand
              icon: icon,
              // eslint-disable-next-line object-shorthand
              map: mapRef.current,
            });
          }
        });
    }
  };

  useEffect(() => {
    if (routeDetailId && !routeDetail) {
      handleGetRoute(routeDetailId, () => {
        TrackManifest();
      });
      window.history.replaceState({}, document.title);
    } else {
      TrackManifest();
    }
  }, []);

  const handleCancel = () => {
    isDirty ? setOpenSaveEditingModal(true) : setRouteEditing(undefined);
  };

  const refetchManifests = () =>
    manifests &&
    dispatch(
      fetchAllManifestByDateAsync({
        date: moment(routeDetail.deliveryDate).format(TIME_FORMAT.REQUEST),
        isFetchETA: true,
      })
    );

  // Handle eddit future
  const saveEditingRoutes = useSaveEditingRoutes();
  const { isSuccess: saveEditSuccess, isLoading: editIsSaving } =
    saveEditingRoutes;

  const handleCancelSave = () => {
    try {
      finishPendingRequests("cancelFetchETAInEditDetailMode");
      isOpenSaveEditingModal && setOpenSaveEditingModal(false);
      setRouteEditing(undefined);
    } catch (e) {
      console.log("error: ", e);
    }
  };

  const handleSaveEditing = () => {
    if (isEmpty(routesMoving)) {
      saveEditingRoutes.mutate([routeEditing]);
    } else {
      saveEditingRoutes.mutate([routeEditing].concat(routesMoving));
    }

    isOpenSaveEditingModal && setOpenSaveEditingModal(false);
  };

  useEffect(() => {
    if (saveEditSuccess) {
      const unallocatedDocumentCount = unallocateList.length;
      const unllocated = unallocatedDocumentCount;
      SnackbarUtils.success(
        unllocated
          ? `Route <b>${
              routeDetail.route.name
            }</b> have been saved and ${unllocated} order${
              unllocated ? "s" : ""
            } unallocated.`
          : `Route <b>${routeDetail.route.name}</b> have been saved.`
      );
      setUnallocate([]);
      if (manifests) {
        if (manifests.length) {
          dispatch(
            updateManifests({
              manifests: [routeEditing],
              unallocatedDocumentCount,
            })
          );
        }
      }
      // refetchManifests();
      setRouteDetail(routeEditing);
      setRouteEditing(undefined);
      setRoutesMoving([]);
      window.history.replaceState({}, document.title);
    }
    // eslint-disable-next-line
  }, [saveEditSuccess]);

  useEffect(() => {
    if (isEditing) {
      const isDiff =
        JSON.stringify(routeDetail.manifestLines) !==
        JSON.stringify((routeEditing as manifest)?.manifestLines);

      isDiff !== isDirty && setDirty(isDiff);
    }
    // eslint-disable-next-line
  }, [routeDetail, routeEditing]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isEditing]);

  const handleBeforeUnload = (e: any) => {
    e.preventDefault();
    const message =
      "There are some changes have been made. Are you sure to cancel all changes?";

    if (isEditing) {
      e.returnValue = message;
    } else {
      delete e.returnValue;
    }
    return message;
  };

  // End Handle eddit future

  // Handle force stop
  const forceStopDelivering = useForceStopDelivering();
  const { isSuccess: forceStopSuccess, isLoading: stopIsForcing } =
    forceStopDelivering;

  useEffect(() => {
    if (forceStopSuccess) {
      SnackbarUtils.success(
        `Route <b>${routeDetail.route.name}</b> force stop successfully.`
      );
      routeDetailId && handleGetRoute(routeDetailId, () => {});
      refetchManifests();
    }
    // eslint-disable-next-line
  }, [forceStopSuccess]);
  // End Handle force stop

  const handleGetRoute = (routeDetailId: string, fn: () => void) => {
    setLoading(true);
    getRouteById(routeDetailId)
      .then((res) => {
        setLoading(false);
        setIsManifestETALoading(true);
        setRouteDetail(res);
        getRouteETAWithMapIds([routeDetailId])
          .then((ETARes) => {
            setIsManifestETALoading(false);
            setRouteDetail(mergeETAWithRoute(res, ETARes[0]));
            console.log("fn()");
            fn();
          })
          .catch(() => setIsManifestETALoading(false));
      })
      .catch(() => setLoading(false));
  };

  const handleUnallocate = (manifestLine?: manifestLine) => {
    if (manifestLine) {
      const list = [...unallocateList, manifestLine];
      setUnallocate(list);
      const route = {
        ...routeEditing,
        manifestLines: routeEditing.manifestLines
          .filter((item: manifestLine) => item.id !== manifestLine.id)
          .map((item: manifestLine, index: number) => {
            return {
              ...item,
              position: index,
            };
          }),
      };
      setRouteEditing(route);
      handleLoadTempETAData(route);
    }
  };

  const mergeStartTimeWithRoute = (
    route: manifest,
    fixedStartTime: string | null
  ) => {
    return {
      ...route,
      fixedStartTime,
    };
  };

  const mergeETAWithRoute = (
    route: manifest,
    etaData: manifestDetailETA
  ) => {
    return {
      ...route,
      ...etaData,
      manifestLines: route.manifestLines.map((mfl: manifestLine) => {
        const newETATime = etaData.manifestLine.find(
          (etaTime) => mfl.id === etaTime.id
        );
        return {
          ...mfl,
          ...newETATime,
        };
      }),
      startTime: etaData.startTime,
      endTime: etaData.endTime,
    };
  };

  // Refetch temp ETA
  const handleLoadTempETAData = async (route: manifest) => {
    setIsManifestETALoading(true);
    getManifestDetailTimeETA([route])
      .then((res: any) => {
        setIsManifestETALoading(false);
        const resData = res[0] as manifestDetailETA;
        if (resData) {
          const newRoute = mergeETAWithRoute({ ...route }, resData);
          setRouteEditing(newRoute);
          // setRouteDetail(newRoute);
        }
      })
      .catch(() => {
        setIsManifestETALoading(false);
      });
  };

  const handleChangePositionManifestLine = (route: manifest) => {
    setRouteEditing(route);
    handleLoadTempETAData(route);
  };


  const changeStartTime = useChangeStartTime();
  const { isSuccess: isChangeStartTimeSuccess, isLoading: isChangeStartTimeLoading } = changeStartTime;
  useEffect(() => {
    if (isChangeStartTimeSuccess) {
      SnackbarUtils.success(
        `Changed start time successfully.`
      );

      if (isEditing) {
        handleChangePositionManifestLine(routeEditing);
      } else {
        routeDetailId && handleGetRoute(routeDetailId, () => {});
        refetchManifests();
      }
    } else {
      setIsManifestETALoading(false);
    }
    // eslint-disable-next-line
  }, [isChangeStartTimeSuccess]);

  const openEditStartTime = () => {
    setOpenChangeStartTimeModal(true);
  };
  const onCloseChangeStartTime = () => {
    setOpenChangeStartTimeModal(false);
  };
  const onSubmitChangeStartTime = (fixedStartTime: string | null) => {
    console.log(fixedStartTime);

    changeStartTime.mutate({ manifestId : routeDetail.id, fixedStartTime, action: () => {
      setIsManifestETALoading(true);
    }});
    onCloseChangeStartTime();
  };

  const onResetChangeStartTime = (fixedStartTime: string | null) => {

  };
  

  return (
    <LayoutPartials.BodyContent
      pageTitle={
        <div className="flex items-center">
          <div>{passedData?.route?.name || routeDetail?.route?.name}</div>
          {Boolean(routeDetail?.status) &&
            routeDetail?.status !== DeliveryStatusValue.PENDING && (
              <DeliveryStatusLabel
                status={routeDetail?.status}
                className="ml-2"
              />
            )}
          <div className="flex-1 border-l border-neutral-20 pl-5 ml-5">
            <div className="flex items-center">
              <div className="text-neutral-70 text-sm">
                <div className="text-xs text-neutral-50 review-detail">Start time</div>
                {(isEditing && routeEditing) ? timeFormatHeader(routeEditing?.startTime) : timeFormatHeader(routeDetail?.startTime)}
              </div>
              <Icon.TimeTo size="12" className="fill-neutral-40 mx-2.5" />
              <div className="text-neutral-70 text-sm">
                <div className="text-xs text-neutral-50">End time</div>
                {(isEditing && routeEditing) ? timeFormatHeader(routeEditing?.endTime) : timeFormatHeader(routeDetail?.endTime)}
              </div>

              {routeDetail && (
                <div className="text-neutral-70 text-sm ml-10">
                  <div className="text-xs text-neutral-50">
                    Driver break period
                  </div>
                  {`${timeFormat(
                    routeDetail.driver.breakStartTime
                  )} - ${timeFormat(routeDetail.driver.breakEndTime)}`}
                </div>
              )}

              {routeDetail && routeDetail.status === DeliveryStatusValue.PENDING &&
                <>
                <Common.Button
                  btSize="sm"
                  onClick={openEditStartTime}
                  outline
                  className="ml-5"
                  iconLeft={Icon.Time}
                  isLoading={isChangeStartTimeLoading || isManifestETALoading}
                  disabled={isChangeStartTimeLoading || isManifestETALoading}
                >
                  Change start time
                </Common.Button>
              </>
              }
            </div>
          </div>
        </div>
      }
      onTitleBack={() =>
        isEditing ? setIsOpenBackInformModal(true) : handleBack()
      }
      disabledBackButton={editIsSaving || isManifestETALoading}
      rightContent={
        <>
          {routeDetail &&
            DeliveryStatusValue.DELIVERING === routeDetail.status && (
              <>
                <Common.Button
                  outline
                  color="red"
                  className="ml-2"
                  onClick={() => forceStopDelivering.mutate(routeDetail.id)}
                  isLoading={stopIsForcing}
                  disabled={stopIsForcing}
                >
                  Force stop
                </Common.Button>
              </>
            )}
          {routeDetail &&
            Boolean(routeDetail.manifestLines.length) &&
            CanModifyRouteByStatus.includes(routeDetail.status) && (
              <>
                {!isEditing ? (
                  <Common.Button
                    onClick={handleEnableEdit}
                    outline
                    disabled={isManifestETALoading}
                    iconLeft={Icon.Edit}
                  >
                    Edit
                  </Common.Button>
                ) : (
                  <>
                    <Common.Button
                      onClick={handleCancel}
                      color="transparent"
                      disabled={editIsSaving}
                    >
                      Cancel
                    </Common.Button>
                    <Common.Button
                      onClick={handleSaveEditing}
                      className="ml-2"
                      isLoading={editIsSaving}
                      disabled={
                        !isDirty || editIsSaving || isManifestETALoading
                      }
                    >
                      Save
                    </Common.Button>
                  </>
                )}
              </>
            )}
        </>
      }
    >
      <div className="flex h-[calc(100vh-12.75rem)]">
        <div
          className={clsx(
            "w-[28rem] mr-4 bg-neutral-5 relative"
            // isHideDetails && "hidden"
          )}
        >
          {editIsSaving && (
            <div className="absolute top-0 left-0 h-full w-full z-10 bg-black bg-opacity-30 flex justify-center items-center rounded">
              <Icon.Loading size="28" />
            </div>
          )}
          {routeDetail ? (
            <>
              <div className="flex items-center p-4 pb-6">
                <div className="text-hd5 font-semibold flex-1">
                  {destinationNumber} Destination
                  {destinationNumber > 1 && "s"}
                </div>
              </div>
              <div className="h-[calc(100%-4.75rem)]">
                <CustomScrollbar>
                  {routeDetail.manifestLines.length ? (
                    <>
                      {isEditing ? (
                        <DeliveryRouteDetailEditingList
                          onListChange={handleChangePositionManifestLine}
                          onUnallocate={handleUnallocate}
                          data={routeEditing}
                          // onMoveItem={handleMoveManifestLine}
                          manifestETALoading={isManifestETALoading}
                          routeInfo={routeDetail}
                        />
                      ) : (
                        <DeliveryRouteDetailList
                          routeInfo={routeDetail}
                          data={routeDetail?.manifestLines || []}
                          manifestETALoading={isManifestETALoading}
                          isEditing = {isEditing}
                          routeEditing = {routeEditing}
                        />
                      )}
                    </>
                  ) : (
                    <Common.NoData
                      title="No order found in this delivery manifest."
                      bottomElement={
                        <Common.Button
                          className="mt-3"
                          onClick={handleBackNoneData}
                        >
                          Go back to manifest list
                        </Common.Button>
                      }
                    />
                  )}
                </CustomScrollbar>
              </div>
            </>
          ) : isLoading ? (
            <Common.NoData
              iconRender={Icon.Loading}
              title="Route detail is loading...."
            />
          ) : (
            <Common.NoData
              title="Route does not exist."
              bottomElement={
                <Common.Button className="mt-3" onClick={handleBackNoneData}>
                  Go to Delivery Manifest page
                </Common.Button>
              }
            />
          )}
        </div>
        <div className="flex-1 rounded bg-neutral-10 flex items-center justify-center relative">
          <div className="ralative z-0 w-full h-full">
            <GoogleMap
              onLoad={onLoad}
              storageAddress={storageAddress}
              manifests={
                isEditing && routeEditing
                  ? [routeEditing]
                  : routeDetail
                  ? [routeDetail]
                  : []
              }
            />
          </div>
        </div>
      </div>
      <ModalInform
        open={isOpenSaveEditingModal}
        onClose={handleCancelSave}
        title="Are you sure you want to cancel editing?"
        description="It looks like you have made changes to this route. If you cancel now, your changes will not be saved. Would you like to save these changes?"
        type="error"
        groupBtn={
          <div className="mt-8 flex justify-center">
            <Common.Button color="transparent" onClick={handleCancelSave}>
              <span className="text-red">Cancel editing</span>
            </Common.Button>
            <Common.Button className="ml-4" onClick={handleSaveEditing}>
              Save changes
            </Common.Button>
          </div>
        }
      />
      <ModalInform
        open={isOpenBackInformModal}
        type="warning"
        title="There are some changes have been made. Are you sure to cancel all changes?"
        onClose={() => setIsOpenBackInformModal(false)}
        groupBtn={
          <div className="mt-8 flex justify-center">
            <Common.Button
              color="transparent"
              onClick={() => setIsOpenBackInformModal(false)}
            >
              Close
            </Common.Button>
            <Common.Button className="ml-4" onClick={handleBack}>
              Cancel editing
            </Common.Button>
          </div>
        }
      />
      <ModalChangeStartTime
        open={isOpenChangeStartTimeModal}
        data={routeDetail}
        onClose={onCloseChangeStartTime}
        onSubmit={onSubmitChangeStartTime}
        onReset={onResetChangeStartTime}
      />
    </LayoutPartials.BodyContent>
  );
};

export default DeliveryRouteDetail;
